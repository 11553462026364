import { AddOutlined, ArrowCircleRightOutlined, CheckCircleOutlined, NotificationsActiveOutlined, RoomServiceOutlined } from '@mui/icons-material';
import { Avatar, Badge, Box, CircularProgress, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, Stack, Typography, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { useRouter } from 'next/router';
import { useRef, useMemo, useEffect } from 'react';
import Draggable from 'react-draggable';
import { useInterval } from 'usehooks-ts';
import { LoadingButton } from '@mui/lab';
import { closeSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { tokens } from 'locales/tokens';
import { usePopover } from '~served/ui-comps';
import { dayjs } from '~served/utils';
import { useGetOperationalOrders } from '~/queries/useGetOperationalOrders';
import { useAuthCache } from '~/queries/useAuthCache';
import { useIsReady, useSetError } from '~/store';
import { useGetOperationalNotifications } from '~/queries/useGetOperationalNotifications';
import { resolveAssistanceMutationFn } from '~/queries/useResolveAssistance';
enum RECORD_TYPE {
  ORDER = 'ORDER',
  NOTIFICATION = 'NOTIFICATION',
}
export const OperationalNotification = () => {
  const router = useRouter();
  const {
    t
  } = useTranslation();
  const isReady = useIsReady();
  const {
    data: auth
  } = useAuthCache();
  const {
    data: {
      placed: orders
    }
  } = useGetOperationalOrders(false);
  const prevOrders = useRef(orders);
  const {
    data: notifications
  } = useGetOperationalNotifications(false);
  const popover = usePopover<HTMLButtonElement>();
  const resolveAssistancePopover = usePopover<HTMLButtonElement, {
    id: string;
  }>({
    id: ''
  });
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const setError = useSetError();
  const records = useMemo(() => {
    return [...orders.map(o => ({
      ...o,
      $type: RECORD_TYPE.ORDER
    })), ...notifications.map(o => ({
      ...o,
      $type: RECORD_TYPE.NOTIFICATION
    }))].sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at)));
  }, [orders, notifications]);

  // track orders no longer in the `placed` list and close their snackbar
  useEffect(() => {
    prevOrders.current.filter(o => orders.every(no => no._id !== o._id)).map(o => o._id).map(closeSnackbar);
    prevOrders.current = orders;
  }, [orders]);
  useInterval(() => {
    if (audioPlayerRef.current) audioPlayerRef.current.play();else {
      audioPlayerRef.current = new Audio('/audios/ding.mp3');
      audioPlayerRef.current.play();
    }
  }, !!records.length && isReady ? 15 * 1000 : null);
  if (!isReady) return null;
  return <Zoom in={!!records.length} data-sentry-element="Zoom" data-sentry-component="OperationalNotification" data-sentry-source-file="OperationalNotification.tsx">
			<Box sx={{
      position: 'fixed',
      zIndex: 1299,
      left: 300,
      top: -99
    }} data-sentry-element="Box" data-sentry-source-file="OperationalNotification.tsx">
				<Draggable defaultPosition={{
        x: 0,
        y: 111
      }} data-sentry-element="Draggable" data-sentry-source-file="OperationalNotification.tsx">
					<Badge hidden={!records.length} badgeContent={records.length} color="primary" data-sentry-element="Badge" data-sentry-source-file="OperationalNotification.tsx">
						<Fab ref={popover.anchorRef} onClick={() => {
            if (!auth) return setError('Login first');
            popover.handleOpen();
          }} data-sentry-element="Fab" data-sentry-source-file="OperationalNotification.tsx">
							<NotificationsActiveOutlined data-sentry-element="NotificationsActiveOutlined" data-sentry-source-file="OperationalNotification.tsx" />
						</Fab>

						<BlinkedCircularProgress color="error" thickness={2} size={48} variant="determinate" value={100} data-sentry-element="BlinkedCircularProgress" data-sentry-source-file="OperationalNotification.tsx" />

						<Popover anchorEl={popover.anchorRef.current} anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }} transformOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }} disableScrollLock open={popover.isOpen} onClose={popover.handleClose} PaperProps={{
            sx: {
              width: 380
            }
          }} data-sentry-element="Popover" data-sentry-source-file="OperationalNotification.tsx">
							<Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2} sx={{
              px: 3,
              py: 2
            }} data-sentry-element="Stack" data-sentry-source-file="OperationalNotification.tsx">
								<Typography color="inherit" variant="h6" data-sentry-element="Typography" data-sentry-source-file="OperationalNotification.tsx">
									Notifications
								</Typography>
							</Stack>
							<List disablePadding data-sentry-element="List" data-sentry-source-file="OperationalNotification.tsx">
								{records.map(obj => {
                if (obj.$type === RECORD_TYPE.ORDER) return <ListItem key={obj._id} secondaryAction={<IconButton color="primary" onClick={() => {
                  if (!auth) return setError('Login first');
                  router.push(`/ops/orders?id=${obj._id}`).then(popover.handleClose);
                }}>
														<ArrowCircleRightOutlined />
													</IconButton>}>
												<ListItemAvatar>
													<Avatar>
														<AddOutlined />
													</Avatar>
												</ListItemAvatar>
												<ListItemText primary={<Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
															<Typography sx={{
                      mr: 0.5
                    }} variant="subtitle2">
																{obj._location.name}
															</Typography>
															<Typography sx={{
                      mr: 0.5
                    }} variant="body2">
																has a new order
															</Typography>
															<Typography variant="body2">#{obj['index']}</Typography>
														</Box>} secondary={<Typography color="text.secondary" variant="caption">
															{dayjs(obj.created_at).fromNow()}
														</Typography>} sx={{
                    my: 0
                  }} />
											</ListItem>;
                if (obj.$type === RECORD_TYPE.NOTIFICATION) return <ListItem key={obj._id} selected={resolveAssistancePopover.data?.id === obj._id} secondaryAction={<IconButton color="primary" onClick={async e => {
                  resolveAssistancePopover.anchorRef.current = e.currentTarget;
                  if (!auth) return setError('Login first');
                  resolveAssistancePopover.handleOpen({
                    id: obj._id
                  });
                }}>
														<CheckCircleOutlined />
													</IconButton>}>
												<ListItemAvatar>
													<Avatar>
														<RoomServiceOutlined />
													</Avatar>
												</ListItemAvatar>
												<ListItemText primary={<Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
															<Typography sx={{
                      mr: 0.5
                    }} variant="subtitle2">
																{obj._location.name}
															</Typography>
															{!obj['content'] && <Typography sx={{
                      mr: 0.5
                    }} variant="body2">
																	needs staff
																</Typography>}
														</Box>} secondary={<>
															{!!obj['content'] && <Typography color="text.primary" variant="body2">
																	{obj['content']}
																</Typography>}
															<Typography color="text.secondary" variant="caption">
																{dayjs(obj.created_at).fromNow()}
															</Typography>
														</>} sx={{
                    my: 0
                  }} />
											</ListItem>;
                return null;
              })}
							</List>

							<Popover anchorEl={resolveAssistancePopover.anchorRef.current} anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom'
            }} transformOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }} disableScrollLock open={resolveAssistancePopover.isOpen} onClose={resolveAssistancePopover.handleClose} data-sentry-element="Popover" data-sentry-source-file="OperationalNotification.tsx">
								<Stack spacing={2} sx={{
                px: 3,
                py: 2
              }} data-sentry-element="Stack" data-sentry-source-file="OperationalNotification.tsx">
									<Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="OperationalNotification.tsx">Mark this assistance as resolved?</Typography>
									<Box display="flex" width={1} gap={2} justifyContent="flex-end" data-sentry-element="Box" data-sentry-source-file="OperationalNotification.tsx">
										<LoadingButton variant="outlined" color="inherit" onClick={resolveAssistancePopover.handleClose} data-sentry-element="LoadingButton" data-sentry-source-file="OperationalNotification.tsx">
											{t(tokens.global.cancel)}
										</LoadingButton>
										<LoadingButton color="primary" onClick={() => resolveAssistanceMutationFn({
                    id: resolveAssistancePopover.data!.id
                  }).then(resolveAssistancePopover.handleClose)} data-sentry-element="LoadingButton" data-sentry-source-file="OperationalNotification.tsx">
											{t(tokens.global.yes)}
										</LoadingButton>
									</Box>
								</Stack>
							</Popover>
						</Popover>
					</Badge>
				</Draggable>
			</Box>
		</Zoom>;
};
const ping = keyframes`
		75%, 100% {
			transform: scale(1.15);
			opacity: 0;
		}
	`;
const BlinkedCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  right: 0,
  zIndex: 99,
  animation: `${ping} 1s cubic-bezier(0, 0, 0.2, 1) infinite`
});