import { CloseOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Client } from 'graphql-ws';
import Router from 'next/router';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { setState } from '~/store';
import { Data_Event_Type, SubscribeToVenueNotificationsSubscription } from '~/types/__generated/gql/graphql';
import { Notification } from '~/types/store';
import { getAuthCache } from '../useAuthCache';
import { getOrdersCache } from '../useGetOperationalOrders';
import { resolveAssistanceMutationFn } from '../useResolveAssistance';
import { getOperationalNotificationsKey, GetOperationalNotificationsResults } from '../useGetOperationalNotifications';
const query = /* GraphQL */`
	subscription SubscribeToVenueNotifications {
		subscribeToVenueNotifications {
			type
			id
			payload {
				_id
				created_at
				updated_at
				content
				staff
				location
				venue
				device

				_location {
					_id
					name
				}
			}
		}
	}
`;
export const subscribeToVenueNotifications = async (client: Client) => {
  const subscription = client.iterate<SubscribeToVenueNotificationsSubscription>({
    query
  });
  for await (const result of subscription) {
    if (result.data) {
      const {
        type,
        payload
      } = result.data.subscribeToVenueNotifications;
      switch (type) {
        case Data_Event_Type.Create:
          {
            if (!payload) break;
            window.$queryClient?.setQueryData<GetOperationalNotificationsResults>(getOperationalNotificationsKey(), prev => prev ? [...prev, payload] : [payload]);
            const newNotification: Notification = {
              id: payload._id,
              message: payload.content ? `${payload._location.name}: "${payload.content}"` : `${payload._location.name} needs staff`,
              type: 'STAFF_ASSISTANCE',
              severity: 'info'
            };
            addNewNotification(newNotification);
            break;
          }
      }
    }
  }
};
export const addNewNotification = (notification: Notification) => {
  const {
    id,
    message,
    severity
  } = notification;
  const handleClose = async (notification: Notification) => {
    closeSnackbar(notification.id);
    const immediateAuth = getAuthCache();
    const immediateOrders = getOrdersCache();
    if (!!immediateAuth && !!immediateOrders && notification.type === 'NEW_ORDER' && notification.customProps.status) {
      const isOrderStillInPlaced = immediateOrders.placed.some(o => o._id === notification.id);
      if (!isOrderStillInPlaced) return;
      if (Router && 'push' in Router) {
        Router.push('/ops/orders?id=' + notification.id);
      }
    }
    if (!!immediateAuth && notification.type === 'STAFF_ASSISTANCE') await resolveAssistanceMutationFn({
      id: notification.id
    });
  };
  enqueueSnackbar(message, {
    key: id,
    persist: true,
    variant: severity,
    action: () => <IconButton key={id} onClick={() => {
      getAuthCache() ? handleClose(notification) : setState({
        error: 'Login first'
      });
    }}>
				<CloseOutlined />
			</IconButton>
  });
  new Audio('/audios/ding.mp3').play().catch(console.error);
};