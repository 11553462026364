import Router from 'next/router';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import {
	applyServiceChargeToOrder,
	applyServiceChargeToOrderGroup,
	applyVatToOrder,
	applyVatToOrderGroup,
	dayjs,
} from '~served/utils';

import {
	CALENDAR_VIEW,
	DEFAULT_GROUP_NAME,
	LOWEST_DENOMINATOR,
	PREFERRED_LOCATION_VIEW,
	TILL_MODE,
} from '~/configs/constants';
import { browserStorage } from '~/libs/localforage';
import { getCurrentVenueCache } from '~/queries/useGetCurrentVenue';
import {
	getOperationalGeolocationsCache,
	getOperationalGeolocationsKey,
} from '~/queries/useGetOperationalGeolocations';
import {
	getOperationalLocationsCache,
	getOperationalLocationsKey,
} from '~/queries/useGetOperationalLocations';
import { getOperationalOrdersKey, getOrdersCache } from '~/queries/useGetOperationalOrders';
import { CreateOrderItemInput, OrderItem, UpdateOrderItemInput } from '~/types/__generated/gql/graphql';
import { Actions, State } from '~/types/store';
import { createSelectors } from '~/types/utils';
import { DEFAULT_PAGINATION_LIMIT } from '~/utils/pagination';

const INITIAL_STATE: State = {
	error: null,
	info: null,
	isReady: false,
	isConnectedToServer: true,
	isLatest: true,
	isFeatureLockedDialogOpen: false,

	token: null,
	venueId: null,
	ordersIsLoading: true,
	locationsIsLoading: true,

	denominator: LOWEST_DENOMINATOR,
	preferredLocation: DEFAULT_GROUP_NAME,
	preferredLocationView: PREFERRED_LOCATION_VIEW.LIST,
	preferredCalendarView: CALENDAR_VIEW.MONTH,

	reports: {
		summary: {
			from: dayjs().startOf('d').toISOString(),
			to: dayjs().endOf('d').toISOString(),
		},
		receipts: {
			from: dayjs().startOf('d').toISOString(),
			to: dayjs().endOf('d').toISOString(),
		},
		categories: {
			from: dayjs().startOf('d').toISOString(),
			to: dayjs().endOf('d').toISOString(),
		},
		items: {
			from: dayjs().startOf('d').toISOString(),
			to: dayjs().endOf('d').toISOString(),
		},
		staffs: {
			from: dayjs().startOf('d').toISOString(),
			to: dayjs().endOf('d').toISOString(),
		},
		locations: {
			from: dayjs().startOf('d').toISOString(),
			to: dayjs().endOf('d').toISOString(),
		},
		details: {
			from: dayjs().startOf('d').toISOString(),
			to: dayjs().endOf('d').toISOString(),
		},
	},

	till: {
		items: [],
		cancelled_items: [],
		note: undefined,
		customer: undefined,
		location: '',

		headcount: 0,
		payment_types: [],
		cash_received: [],
		cash_returned: [],
		isInternalReceipt: false,
		tips: [],
		ticket_linebreaks: [],
		room_master_reference: null,
		room_master_folio: null,

		is_paid: false,
		prevItems: [],
		orders: [],
		isLoading: false,
		isPaymentsPanelOpen: false,
		selectedCategory: null,
		selectedItem: null,
		selectedCartItem: null,
	},

	ordersLog: {
		filters: {
			from: 0,
			size: DEFAULT_PAGINATION_LIMIT,
			date: null,
			indices: null,
		},
	},

	customers: {
		filters: {
			from: 0,
			size: DEFAULT_PAGINATION_LIMIT,
			query: '',
		},
	},

	printLogs: {
		filters: {
			from: 0,
			size: DEFAULT_PAGINATION_LIMIT,
			date: null,
			orderIndices: null,
			receiptNumbers: null,
		},
	},
};

const _useStore = create<State & Actions>()(
	persist(
		(set, get) => ({
			...INITIAL_STATE,
			resetApp: () => set(INITIAL_STATE),
			setError: (data) => set({ error: data }),
			setInfo: (data) => set({ info: data }),
			setIsReady: (data) => set({ isReady: data }),
			setIsConnectedToServer: (data) => set({ isConnectedToServer: data }),
			setIsLatest: (data) => set({ isLatest: data }),
			setIsFeatureLockedDialogOpen: (data) => set({ isFeatureLockedDialogOpen: data }),
			setToken: (data) => set({ token: data }),
			setVenueId: (data) => set({ venueId: data }),
			logout: (redir) => {
				const { venueId } = get();
				if (!venueId) return;

				const returnUrl = '/login/' + venueId + (redir ? `?redir=${encodeURIComponent(redir)}` : '');
				set((prev) => ({
					...INITIAL_STATE,
					ordersIsLoading: prev.ordersIsLoading,
					locationsIsLoading: prev.locationsIsLoading,
					denominator: prev.denominator,
					preferredLocation: prev.preferredLocation,
					preferredLocationView: prev.preferredLocationView,
					preferredCalendarView: prev.preferredCalendarView,
					venueId,
					isReady: false,
				}));

				const ordersCacheBackup = getOrdersCache();
				const locationsCacheBackup = getOperationalLocationsCache();
				const geolocationsCacheBackup = getOperationalGeolocationsCache();

				window.$queryClient.clear();

				window.$queryClient.setQueryData(getOperationalOrdersKey(), ordersCacheBackup);
				window.$queryClient.setQueryData(getOperationalLocationsKey(), locationsCacheBackup);
				window.$queryClient.setQueryData(getOperationalGeolocationsKey(), geolocationsCacheBackup);

				Promise.all([browserStorage.token.remove(), Router.push(returnUrl)]).then(() =>
					set({ isReady: true })
				);
			},
			setOrdersIsLoading: (data) => set({ ordersIsLoading: data }),
			setLocationsIsLoading: (data) => set({ locationsIsLoading: data }),
			setAppBreadcrumbs: (data) => set((prev) => ({ app: { ...prev.app, breadcrumbs: data } })),
			setReportsSummaryTabFilters: (data) =>
				set((prev) => ({
					reports: { ...prev.reports, summary: { ...prev.reports.summary, ...data } },
				})),
			setReportsReceiptsTabFilters: (data) =>
				set((prev) => ({ reports: { ...prev.reports, receipts: { ...prev.reports.receipts, ...data } } })),
			setReportsCategoriesTabFilters: (data) =>
				set((prev) => ({
					reports: { ...prev.reports, categories: { ...prev.reports.categories, ...data } },
				})),
			setReportsItemsTabFilters: (data) =>
				set((prev) => ({ reports: { ...prev.reports, items: { ...prev.reports.items, ...data } } })),
			setReportsStaffsTabFilters: (data) =>
				set((prev) => ({ reports: { ...prev.reports, staffs: { ...prev.reports.staffs, ...data } } })),
			setReportsLocationsTabFilters: (data) =>
				set((prev) => ({ reports: { ...prev.reports, locations: { ...prev.reports.locations, ...data } } })),
			setReportsDetailsTabFilters: (data) =>
				set((prev) => ({ reports: { ...prev.reports, details: { ...prev.reports.details, ...data } } })),
			resetTill: (data) => set({ till: { ...INITIAL_STATE.till, ...data } }),
			updateTill: (data) => set((prev) => ({ till: { ...prev.till, ...data } })),
			setupTillForSelectedLocation: (data) => {
				const { _id, default_order_note } = data;
				set({ till: { ...INITIAL_STATE.till, location: _id, note: default_order_note } });
			},
			setupTillForOrderEdit: (data) => {
				const { items, cancelled_items, is_paid, location, customer, note, _receipt } = data;
				set({
					till: {
						...INITIAL_STATE.till,
						note,
						location,
						items: getUpdateOrderItemInputFromOrderItems(items),
						cancelled_items: getUpdateOrderItemInputFromOrderItems(cancelled_items),
						is_paid,
						payment_types: _receipt ? _receipt.payment_types : [],
						customer,
					},
				});
			},
			setupTillForAddingToOrder: (data) => {
				const { items, cancelled_items, is_paid, location, _receipt, customer, note } = data;

				set({
					till: {
						...INITIAL_STATE.till,
						note,
						location,
						cancelled_items: getUpdateOrderItemInputFromOrderItems(cancelled_items),
						prevItems: items,
						is_paid,
						payment_types: _receipt ? _receipt.payment_types : [],
						customer,
					},
				});
			},
			setupTillForOrderPayment: (data) => {
				const { items, cancelled_items, location, _receipt, customer, note } = data;

				set({
					till: {
						...INITIAL_STATE.till,
						note,
						is_paid: true,
						location,
						items: getUpdateOrderItemInputFromOrderItems(items),
						cancelled_items: getUpdateOrderItemInputFromOrderItems(cancelled_items),
						payment_types: _receipt ? _receipt.payment_types : [],
						customer,
						isPaymentsPanelOpen: true,
					},
				});
			},
			setupTillForOrderGroupPayment: (data) => {
				const { _id, _current_orders } = data;

				set({
					till: {
						...INITIAL_STATE.till,
						location: _id,
						is_paid: true,
						orders: _current_orders.filter((o) => !o.is_paid && !o.is_cancelled),
						isPaymentsPanelOpen: true,
					},
				});
			},
			setTillIsLoading: (data) =>
				set((prev) => ({ till: { ...prev.till, isLoading: data ?? !prev.till.isLoading } })),
			setTillIsPaymentsPanelOpen: (data) =>
				set((prev) => ({ till: { ...prev.till, isPaymentsPanelOpen: data } })),
			setTillSelectedCategory: (data) => set((prev) => ({ till: { ...prev.till, selectedCategory: data } })),
			setTillSelectedItem: (data) => set((prev) => ({ till: { ...prev.till, selectedItem: data } })),
			setTillSelectedCartItem: (data) => set((prev) => ({ till: { ...prev.till, selectedCartItem: data } })),
			setTillLocation: (data) => {
				const venue = getCurrentVenueCache();
				if (!venue) throw new Error('Venue not found');

				const locations = getOperationalLocationsCache();
				const locationData = locations.find((l) => l._id === data);
				if (!locationData) throw new Error('Location not found');

				const { till } = get();
				const { items, orders } = till;
				const newTill = { ...till };

				const serviceCharge = locationData.no_service_charge ? 0 : venue.service_charge;
				const vat = locationData.no_vat ? 0 : venue.vat;

				newTill.location = data;

				if (items.length) {
					const { items: itemsWithVat } = applyVatToOrder({
						order: { items: items },
						vat,
					});
					const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
						order: { items: itemsWithVat },
						serviceCharge,
					});
					newTill.items = itemsWithServiceCharge as (typeof newTill)['items'];
				}

				if (orders.length) {
					const ordersWithVat = applyVatToOrderGroup({ orders, vat });
					const ordersWithServiceCharge = applyServiceChargeToOrderGroup({
						orders: ordersWithVat,
						serviceCharge,
					});

					newTill.orders = ordersWithServiceCharge as (typeof newTill)['orders'];
				}

				set({ till: newTill });
			},
			setTillItems: (data) => {
				const venue = getCurrentVenueCache();
				if (!venue) throw new Error('Venue not found');

				const { location } = get().till;
				if (!location) throw new Error('No location selected');

				const locations = getOperationalLocationsCache();
				const locationData = locations.find((l) => l._id === location);
				if (!locationData) throw new Error('Location not found');

				const serviceCharge = locationData.no_service_charge ? 0 : venue.service_charge;
				const vat = locationData.no_vat ? 0 : venue.vat;

				const { items: itemsWithVat } = applyVatToOrder({ order: { items: data }, vat });
				const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { items: itemsWithVat },
					serviceCharge,
				});

				set((prev) => ({
					till: {
						...prev.till,
						items:
							String(Router.query.mode || '') === TILL_MODE.EDIT_ORDER
								? getUpdateOrderItemInputFromOrderItems(itemsWithServiceCharge as OrderItem[])
								: getCreateOrderItemInputFromOrderItems(itemsWithServiceCharge as OrderItem[]),
					},
				}));
			},
			setTillCancelledItems: (data) => {
				const venue = getCurrentVenueCache();
				if (!venue) throw new Error('Venue not found');

				const { location } = get().till;
				if (!location) throw new Error('No location selected');

				const locations = getOperationalLocationsCache();
				const locationData = locations.find((l) => l._id === location);
				if (!locationData) throw new Error('Location not found');

				const serviceCharge = locationData.no_service_charge ? 0 : venue.service_charge;
				const vat = locationData.no_vat ? 0 : venue.vat;

				const { cancelled_items: cancelledItemsWithVat } = applyVatToOrder({
					order: { cancelled_items: data, items: [] },
					vat,
				});
				const { cancelled_items: cancelledItemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { cancelled_items: cancelledItemsWithVat, items: [] },
					serviceCharge,
				});
				set((prev) => ({
					till: {
						...prev.till,
						cancelled_items: getUpdateOrderItemInputFromOrderItems(
							cancelledItemsWithServiceCharge as OrderItem[]
						),
					},
				}));
			},
			setDenominator: (data) => {
				set({ denominator: data });
				browserStorage.denominator.set(data);
			},
			setPreferredLocation: (data) => {
				set({ preferredLocation: data });
				browserStorage.preferredLocation.set(data);
			},
			setPreferredLocationView: (data) => {
				set({ preferredLocationView: data });
				browserStorage.preferredLocationView.set(data);
			},
			setPreferredCalendarView: (data) => {
				set({ preferredCalendarView: data });
				browserStorage.preferredCalendarView.set(data);
			},
			updateOrdersLogFilters: (data) => {
				set((prev) => ({
					ordersLog: {
						...prev.ordersLog,
						filters: {
							...prev.ordersLog.filters,
							...data,
						},
					},
				}));
			},
			updateCustomersFilters: (data) => {
				set((prev) => ({
					customers: {
						...prev.customers,
						filters: {
							...prev.customers.filters,
							...data,
						},
					},
				}));
			},
			updatePrintLogsFilters: (data) => {
				set((prev) => ({
					printLogs: {
						...prev.printLogs,
						filters: {
							...prev.printLogs.filters,
							...data,
						},
					},
				}));
			},
		}),
		{
			name: 'served-staff-app-store',
			storage: createJSONStorage(() => window.sessionStorage),
		}
	)
);

const { use, setState, getState } = createSelectors(_useStore);

const useError = () => {
	return use.error();
};

const useSetError = () => {
	return use.setError();
};
const useInfo = () => {
	return use.info();
};
const useSetInfo = () => {
	return use.setInfo();
};
const useIsReady = () => {
	return use.isReady();
};
const useSetIsReady = () => {
	return use.setIsReady();
};
const useIsConnectedToServer = () => {
	return use.isConnectedToServer();
};
const useSetIsConnectedToServer = () => {
	return use.setIsConnectedToServer();
};
const useIsLatest = () => {
	return use.isLatest();
};
const useSetIsLatest = () => {
	return use.setIsLatest();
};
const useIsFeatureLockedDialogOpen = () => {
	return use.isFeatureLockedDialogOpen();
};
const useSetIsFeatureLockedDialogOpen = () => {
	return use.setIsFeatureLockedDialogOpen();
};
const useToken = () => {
	return use.token();
};
const useSetToken = () => {
	return use.setToken();
};
const useVenueId = () => {
	return use.venueId();
};
const useSetVenueId = () => {
	return use.setVenueId();
};
const useCart = () => {
	return use.till();
};
const useResetCart = () => {
	return use.resetTill();
};
const useLogout = () => {
	return use.logout();
};
const useOrdersIsLoading = () => {
	return use.ordersIsLoading();
};
const useSetOrdersIsLoading = () => {
	return use.setOrdersIsLoading();
};
const useLocationsIsLoading = () => {
	return use.locationsIsLoading();
};
const useSetLocationsIsLoading = () => {
	return use.setLocationsIsLoading();
};
const useAppBreadcrumbs = () => {
	return _useStore((s) => s.app?.breadcrumbs);
};
const useSetAppBreadcrumbs = () => {
	return use.setAppBreadcrumbs();
};
const useReportsSummaryTabFilters = () => {
	return _useStore((store) => store.reports.summary);
};
const useSetReportsSummaryTabFilters = () => {
	return use.setReportsSummaryTabFilters();
};
const useReportsReceiptsTabFilters = () => {
	return _useStore((store) => store.reports.receipts);
};
const useSetReportsReceiptsTabFilters = () => {
	return use.setReportsReceiptsTabFilters();
};
const useReportsCategoriesTabFilters = () => {
	return _useStore((store) => store.reports.categories);
};
const useSetReportsCategoriesTabFilters = () => {
	return use.setReportsCategoriesTabFilters();
};
const useReportsItemsTabFilters = () => {
	return _useStore((store) => store.reports.items);
};
const useSetReportsItemsTabFilters = () => {
	return use.setReportsItemsTabFilters();
};
const useReportsStaffsTabFilters = () => {
	return _useStore((store) => store.reports.staffs);
};
const useSetReportsStaffsTabFilters = () => {
	return use.setReportsStaffsTabFilters();
};
const useReportsLocationsTabFilters = () => {
	return _useStore((store) => store.reports.locations);
};
const useSetReportsLocationsTabFilters = () => {
	return use.setReportsLocationsTabFilters();
};
const useReportsDetailsTabFilters = () => {
	return _useStore((store) => store.reports.details);
};
const useSetReportsDetailsTabFilters = () => {
	return use.setReportsDetailsTabFilters();
};
const useTillOrders = () => {
	return _useStore((store) => store.till.orders);
};
const useTillItems = () => {
	return _useStore((store) => store.till.items);
};
const useTillCancelledItems = () => {
	return _useStore((store) => store.till.cancelled_items);
};
const useTillPaymentTypes = () => {
	return _useStore((store) => store.till.payment_types);
};
const useTillIsPaid = () => {
	return _useStore((store) => store.till.is_paid);
};
const useTillLocation = () => {
	return _useStore((store) => store.till.location);
};
const useTillHeadcount = () => {
	return _useStore((store) => store.till.headcount);
};
const useTillCustomer = () => {
	return _useStore((store) => store.till.customer);
};
const useTillPaymentsReceived = () => {
	return _useStore((store) => store.till.cash_received);
};
const useTillPaymentsReturned = () => {
	return _useStore((store) => store.till.cash_returned);
};
const useTillNote = () => {
	return _useStore((store) => store.till.note);
};
const useResetTill = () => {
	return use.resetTill();
};
const useUpdateTill = () => {
	return use.updateTill();
};
const useSetupTillForSelectedLocation = () => {
	return use.setupTillForSelectedLocation();
};
const useSetupTillForOrderEdit = () => {
	return use.setupTillForOrderEdit();
};
const useSetupTillForAddingToOrder = () => {
	return use.setupTillForAddingToOrder();
};
const useSetupTillForOrderPayment = () => {
	return use.setupTillForOrderPayment();
};
const useSetupTillForOrderGroupPayment = () => {
	return use.setupTillForOrderGroupPayment();
};
const useTillIsInternalReceipt = () => {
	return _useStore((store) => store.till.isInternalReceipt);
};
const useTillIsLoading = () => {
	return _useStore((store) => store.till.isLoading);
};
const useTillIsPaymentsPanelOpen = () => {
	return _useStore((store) => store.till.isPaymentsPanelOpen);
};
const useTillSelectedCategory = () => {
	return _useStore((store) => store.till.selectedCategory);
};
const useTillSelectedItem = () => {
	return _useStore((store) => store.till.selectedItem);
};
const useTillPrevItems = () => {
	return _useStore((store) => store.till.prevItems);
};
const useSetTillIsLoading = () => {
	return use.setTillIsLoading();
};
const useSetTillIsPaymentsPanelOpen = () => {
	return use.setTillIsPaymentsPanelOpen();
};
const useSetTillSelectedCategory = () => {
	return use.setTillSelectedCategory();
};
const useSetTillSelectedItem = () => {
	return use.setTillSelectedItem();
};
const useTillSelectedCartItem = () => {
	return _useStore((store) => store.till.selectedCartItem);
};
const useSetTillSelectedCartItem = () => {
	return use.setTillSelectedCartItem();
};
const useSetTillLocation = () => {
	return use.setTillLocation();
};
const useSetTillItems = () => {
	return use.setTillItems();
};
const useSetTillCancelledItems = () => {
	return use.setTillCancelledItems();
};
const useResetApp = () => {
	return use.resetApp();
};
const useTillRoomMasterFolio = () => {
	return _useStore((store) => store.till.room_master_folio);
};
const useTillRoomMasterRef = () => {
	return _useStore((store) => store.till.room_master_reference);
};
const useTillTips = () => {
	return _useStore((store) => store.till.tips);
};
const useTillTicketLinebreaks = () => {
	return _useStore((store) => store.till.ticket_linebreaks);
};
const useDenominator = () => {
	return _useStore((store) => store.denominator);
};
const usePreferredLocation = () => {
	return _useStore((store) => store.preferredLocation);
};
const usePreferredLocationView = () => {
	return _useStore((store) => store.preferredLocationView);
};
const usePreferredCalendarView = () => {
	return _useStore((store) => store.preferredCalendarView);
};
const useSetDenominator = () => {
	return use.setDenominator();
};
const useSetPreferredLocation = () => {
	return use.setPreferredLocation();
};
const useSetPreferredLocationView = () => {
	return use.setPreferredLocationView();
};
const useSetPreferredCalendarView = () => {
	return use.setPreferredCalendarView();
};

const getCreateOrderItemInputFromOrderItems = (items: OrderItem[]) =>
	items.map(
		(i) =>
			({
				_id: i._id,
				category: i.category,
				item: i.item,
				unit_price: i.unit_price,
				options: i.options,
				quantity: i.quantity,
				comments: i.comments,
				cancelled_reason: i.cancelled_reason,
				subtotal: i.subtotal,
				subtotal_addons: i.subtotal_addons,
				net_amount: i.net_amount,
				gross_amount: i.gross_amount,
				cancelled_amount: i.cancelled_amount,
				title: i.title,
				original_price: i.original_price,
				original_price_addons: i.original_price_addons,
				listed_price: i.listed_price,
				extra_quantity: i.extra_quantity,
				serving_quantity: i.serving_quantity,
				type: i.type,
				prep_time: i.prep_time,
				printer_tag: i.printer_tag,
				recipe: i.recipe,
			} as CreateOrderItemInput)
	);

const getUpdateOrderItemInputFromOrderItems = (items: OrderItem[]) =>
	items.map(
		(i) =>
			({
				_id: i._id,
				category: i.category,
				item: i.item,
				unit_price: i.unit_price,
				options: i.options,
				quantity: i.quantity,
				comments: i.comments,
				cancelled_reason: i.cancelled_reason,
				subtotal: i.subtotal,
				subtotal_addons: i.subtotal_addons,
				net_amount: i.net_amount,
				gross_amount: i.gross_amount,
				cancelled_amount: i.cancelled_amount,
				title: i.title,
				original_price: i.original_price,
				original_price_addons: i.original_price_addons,
				listed_price: i.listed_price,
				extra_quantity: i.extra_quantity,
				serving_quantity: i.serving_quantity,
				type: i.type,
				prep_time: i.prep_time,
				printer_tag: i.printer_tag,
				delivered_count: i.delivered_count,
				status: i.status,
				recipe: i.recipe,
				is_paid: i.is_paid,
			} as UpdateOrderItemInput)
	);

export {
	getCreateOrderItemInputFromOrderItems,
	getState,
	getUpdateOrderItemInputFromOrderItems,
	setState,
	useAppBreadcrumbs,
	useCart,
	useDenominator,
	useError,
	useInfo,
	useIsConnectedToServer,
	useIsFeatureLockedDialogOpen,
	useIsLatest,
	useIsReady,
	useLocationsIsLoading,
	useLogout,
	useOrdersIsLoading,
	usePreferredCalendarView,
	usePreferredLocation,
	usePreferredLocationView,
	useReportsCategoriesTabFilters,
	useReportsDetailsTabFilters,
	useReportsItemsTabFilters,
	useReportsLocationsTabFilters,
	useReportsReceiptsTabFilters,
	useReportsStaffsTabFilters,
	useReportsSummaryTabFilters,
	useResetApp,
	useResetCart,
	useResetTill,
	useSetAppBreadcrumbs,
	useSetDenominator,
	useSetError,
	useSetInfo,
	useSetIsConnectedToServer,
	useSetIsFeatureLockedDialogOpen,
	useSetIsLatest,
	useSetIsReady,
	useSetLocationsIsLoading,
	useSetOrdersIsLoading,
	useSetPreferredCalendarView,
	useSetPreferredLocation,
	useSetPreferredLocationView,
	useSetReportsCategoriesTabFilters,
	useSetReportsDetailsTabFilters,
	useSetReportsItemsTabFilters,
	useSetReportsLocationsTabFilters,
	useSetReportsReceiptsTabFilters,
	useSetReportsStaffsTabFilters,
	useSetReportsSummaryTabFilters,
	useSetTillCancelledItems,
	useSetTillIsLoading,
	useSetTillIsPaymentsPanelOpen,
	useSetTillItems,
	useSetTillLocation,
	useSetTillSelectedCartItem,
	useSetTillSelectedCategory,
	useSetTillSelectedItem,
	useSetToken,
	useSetupTillForAddingToOrder,
	useSetupTillForOrderEdit,
	useSetupTillForOrderGroupPayment,
	useSetupTillForOrderPayment,
	useSetupTillForSelectedLocation,
	useSetVenueId,
	useTillCancelledItems,
	useTillCustomer,
	useTillHeadcount,
	useTillIsInternalReceipt,
	useTillIsLoading,
	useTillIsPaid,
	useTillIsPaymentsPanelOpen,
	useTillItems,
	useTillLocation,
	useTillNote,
	useTillOrders,
	useTillPaymentsReceived,
	useTillPaymentsReturned,
	useTillPaymentTypes,
	useTillPrevItems,
	useTillSelectedCartItem,
	useTillSelectedCategory,
	useTillSelectedItem,
	useTillTicketLinebreaks,
	useTillTips,
	useToken,
	useUpdateTill,
	useVenueId,
	useTillRoomMasterFolio,
	useTillRoomMasterRef,
};

export const useOrdersLogFilters = () => {
	return _useStore((store) => store.ordersLog.filters);
};
export const useUpdateOrdersLogFilters = () => {
	return use.updateOrdersLogFilters();
};
export const useCustomersFilters = () => {
	return _useStore((store) => store.customers.filters);
};
export const useUpdateCustomersFilters = () => {
	return use.updateCustomersFilters();
};
export const usePrintLogsFilters = () => {
	return _useStore((store) => store.printLogs.filters);
};
export const useUpdatePrintLogsFilters = () => {
	return use.updatePrintLogsFilters();
};
