import type {} from '@mui/lab/themeAugmentation';
import { createTheme, darken, GlobalStyles, lighten, PaletteOptions, ThemeOptions, ThemeProvider, alpha } from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';
import { VenueTheme } from '~served/types/db';
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    aba: {
      main: string;
      light: string;
    };
    khqr: {
      main: string;
    };
  }
  interface PaletteOptions {
    aba: {
      main: string;
      light: string;
    };
    khqr: {
      main: string;
    };
  }
}
const commonPalettes: PaletteOptions = {
  primary: {
    main: '#de6f10',
    light: '#eb8e17',
    dark: '#d4590c'
  },
  secondary: {
    main: '#0c6dcc',
    light: '#1590f1',
    dark: '#054ead'
  },
  aba: {
    main: '#005d7b',
    light: '#10bad2'
  },
  khqr: {
    main: '#e1232e'
  }
};
const components: ThemeOptions['components'] = {
  MuiTextField: {
    defaultProps: {
      margin: 'dense',
      fullWidth: true,
      variant: 'outlined',
      size: 'medium'
    }
  },
  MuiChip: {
    defaultProps: {
      variant: 'filled',
      size: 'medium'
    }
  },
  MuiSwitch: {
    defaultProps: {
      size: 'medium'
    }
  },
  MuiLoadingButton: {
    defaultProps: {
      variant: 'contained',
      size: 'medium'
    }
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      size: 'medium'
    }
  },
  MuiIconButton: {
    defaultProps: {
      size: 'medium'
    }
  },
  MuiFab: {
    defaultProps: {
      size: 'medium'
    }
  },
  MuiPaper: {
    defaultProps: {
      variant: 'outlined'
    }
  },
  MuiCard: {
    defaultProps: {
      variant: 'outlined'
    }
  },
  MuiDataGrid: {
    defaultProps: {
      componentsProps: {
        baseButton: {
          variant: 'outlined',
          sx: {
            mr: 1
          }
        }
      }
    }
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    }
  },
  MuiAlert: {
    defaultProps: {
      variant: 'standard'
    }
  }
};
const MUI_LICENSE_KEY = '54b15a57982fcfd357f997b69b2e34f7T1JERVI6Mzk0NzQsRVhQSVJZPTE2Nzg2MTEzNDAwMDAsS0VZVkVSU0lPTj0x';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
const DefaultThemeProvider = ({
  children,
  venueTheme
}: {
  venueTheme?: VenueTheme;
  children: JSX.Element;
}) => {
  const {
    primary_color,
    primary_text_color
  } = venueTheme || {};
  const mergedTheme: ThemeOptions = {
    palette: {
      mode: 'light',
      ...commonPalettes
    },
    components
  };
  if (primary_color) {
    mergedTheme.palette!.primary = {
      main: primary_color,
      light: lighten(primary_color, 0.35),
      dark: darken(primary_color, 0.35)
    };
  }
  if (primary_text_color) {
    mergedTheme.palette!.text = {
      primary: primary_text_color,
      secondary: lighten(primary_text_color, 0.35),
      disabled: lighten(primary_text_color, 0.7)
    };
  }
  const theme = createTheme(mergedTheme);
  theme.components!.MuiTab = {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.divider, 0.04),
        '&.Mui-selected': {
          backgroundColor: lighten(theme.palette.primary.main, 0.96)
        },
        borderRadius: theme.shape.borderRadius
      }
    }
  };
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="DefaultThemeProvider" data-sentry-source-file="index.tsx">
			<OverwriteGlobalStyles data-sentry-element="OverwriteGlobalStyles" data-sentry-source-file="index.tsx" />
			<NProgressOverwriteGlobalStyles data-sentry-element="NProgressOverwriteGlobalStyles" data-sentry-source-file="index.tsx" />
			<LocalizationProvider dateAdapter={AdapterDayjs} data-sentry-element="LocalizationProvider" data-sentry-source-file="index.tsx">{children}</LocalizationProvider>
		</ThemeProvider>;
};
const DarkThemeProvider = ({
  children,
  venueTheme
}: {
  venueTheme?: VenueTheme;
  children: JSX.Element;
}) => {
  const {
    primary_color,
    primary_text_color
  } = venueTheme || {};
  const mergedTheme: ThemeOptions = {
    palette: {
      mode: 'dark',
      ...commonPalettes
    },
    components
  };
  if (primary_color) {
    mergedTheme.palette!.primary = {
      main: primary_color,
      light: lighten(primary_color, 0.35),
      dark: darken(primary_color, 0.35)
    };
  }
  if (primary_text_color) {
    mergedTheme.palette!.text = {
      primary: primary_text_color,
      secondary: lighten(primary_text_color, 0.35),
      disabled: lighten(primary_text_color, 0.7)
    };
  }
  const theme = createTheme(mergedTheme);
  theme.components!.MuiTab = {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.divider, 0.04),
        '&.Mui-selected': {
          backgroundColor: darken(theme.palette.primary.main, 0.96)
        },
        borderRadius: theme.shape.borderRadius
      }
    }
  };
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="DarkThemeProvider" data-sentry-source-file="index.tsx">
			<OverwriteGlobalStyles data-sentry-element="OverwriteGlobalStyles" data-sentry-source-file="index.tsx" />
			<NProgressOverwriteGlobalStyles data-sentry-element="NProgressOverwriteGlobalStyles" data-sentry-source-file="index.tsx" />
			<LocalizationProvider dateAdapter={AdapterDayjs} data-sentry-element="LocalizationProvider" data-sentry-source-file="index.tsx">{children}</LocalizationProvider>
		</ThemeProvider>;
};
export { DarkThemeProvider, DefaultThemeProvider };
const NProgressOverwriteGlobalStyles = () => <GlobalStyles styles={t => `
			#nprogress {
				pointer-events: none;
			}

			#nprogress .bar {
				background: ${t.palette.primary.main};

				position: fixed;
				z-index: 1031;
				top: 0;
				left: 0;

				width: 100%;
				height: 2px;
			}

			/* Fancy blur effect */
			#nprogress .peg {
				display: block;
				position: absolute;
				right: 0px;
				width: 100px;
				height: 100%;
				box-shadow: 0 0 10px ${t.palette.primary.main}, 0 0 5px ${t.palette.primary.main};
				opacity: 1;

				-webkit-transform: rotate(3deg) translate(0px, -4px);
				-ms-transform: rotate(3deg) translate(0px, -4px);
				transform: rotate(3deg) translate(0px, -4px);
			}

			/* Remove these to get rid of the spinner */
			#nprogress .spinner {
				display: block;
				position: fixed;
				z-index: 1031;
				top: 15px;
				right: 15px;
			}

			#nprogress .spinner-icon {
				width: 18px;
				height: 18px;
				box-sizing: border-box;

				border: solid 2px transparent;
				border-top-color: ${t.palette.primary.main};
				border-left-color: ${t.palette.primary.main};
				border-radius: 50%;

				-webkit-animation: nprogress-spinner 400ms linear infinite;
				animation: nprogress-spinner 400ms linear infinite;
			}

			.nprogress-custom-parent {
				overflow: hidden;
				position: relative;
			}

			.nprogress-custom-parent #nprogress .spinner,
			.nprogress-custom-parent #nprogress .bar {
				position: absolute;
			}

			@-webkit-keyframes nprogress-spinner {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
				}
			}
			@keyframes nprogress-spinner {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		`} data-sentry-element="GlobalStyles" data-sentry-component="NProgressOverwriteGlobalStyles" data-sentry-source-file="index.tsx" />;
const OverwriteGlobalStyles = () => <GlobalStyles styles={t => ({
  'html,body': {
    padding: 0,
    margin: 0,
    fontFamily: "'Roboto', sans-serif",
    color: t.palette.text.primary
  },
  '*': {
    msOverflowStyle: 'none',
    boxSizing: 'border-box'
  },
  '::-webkit-scrollbar': {
    width: '12px',
    height: '12px',
    background: 'transparent'
  },
  '::-webkit-scrollbar-thumb': {
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
    borderRadius: '8px',
    backgroundColor: 'transparent'
  },
  '*:hover::-webkit-scrollbar-thumb': {
    backgroundColor: t.palette.primary.light
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: t.palette.primary.main
  }
})} data-sentry-element="GlobalStyles" data-sentry-component="OverwriteGlobalStyles" data-sentry-source-file="index.tsx" />;