import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
type ColorTextFieldProps = TextFieldProps;

// eslint-disable-next-line react/display-name
const ColorTextField = React.forwardRef((props: ColorTextFieldProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    className,
    ...restTextFieldProps
  } = props;
  return <TextField className={`MuiColorInput-TextField ${className || ''}`} ref={ref} {...restTextFieldProps} />;
});
export default ColorTextField;